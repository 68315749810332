<!--
 * @Auther: Han
 * @Date: 2022-11-27 12:36:55
 * @LastEditTime: 2022-11-27 18:31:58
 * @FilePath: /seVideo/src/App.vue
 * @NewWork: www.vvhan.com
-->
<template>
  <div class="main">
    <div class="ok" v-if="keyuVals == 'friends_20191010'">
      <NavBtn />
      <transition name="el-fade-in-linear">
        <router-view />
      </transition>
    </div>
    <div class="no" v-else>
      <div class="no-con">
        <el-input placeholder="请输入内容" v-model="tempVal" class="input-with-select">
          <el-button slot="append" icon="el-icon-s-promotion" @click="setVals"></el-button>
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    NavBtn: () => import('@/components/NavBtn.vue')
  },
  data() {
    return {
      keyuVals: localStorage.getItem('vvhanKey'),
      tempVal: ''
    }
  },
  methods: {
    setVals() {
      this.keyuVals = this.tempVal
      localStorage.setItem("vvhanKey", this.tempVal)
    }
  },
}
</script>


<style lang="less" scoped>
.main {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  user-select: none;
  overflow: hidden;

  .no {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;

    .no-con {
      width: 86%;
    }
  }
}
</style>
