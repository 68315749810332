import Vue from "vue";
import App from "./App.vue";
import router from "./router";

// 载入Element-UI组件库
import "@/plugins/element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.config.productionTip = false;

new Vue({
  router,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
