/*
 * @Auther: Han
 * @Date: 2022-11-26 21:10:13
 * @LastEditTime: 2022-11-27 17:06:32
 * @FilePath: /web/seVideo/src/router/index.js
 * @NewWork: www.vvhan.com
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/domestic",
    component: () => import("@/views/List"),
    children: [
      {
        name: "gc",
        path: "/domestic",
        component: () => import("@/views/List"),
      },
      {
        name: "zw",
        path: "/captions",
        component: () => import("@/views/List"),
      },
      {
        name: "dm",
        path: "/anime",
        component: () => import("@/views/List"),
      },
    ],
  },
  {
    path: "/video/play",
    name: "Video",
    component: () => import("@/views/Video"),
  },
  {
    path: "*",
    redirect: "/",
    hidden: true,
  },
];
// 防止重复跳转
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
