/*
 * @Auther: Han
 * @Date: 2022-11-26 21:14:18
 * @LastEditTime: 2022-11-27 18:30:28
 * @FilePath: /seVideo/src/plugins/element-ui.js
 * @NewWork: www.vvhan.com
 */
import Vue from "vue";
import { Icon, Loading, Input, Button } from "element-ui";

const elementArr = { Icon, Loading, Input, Button };

Object.values(elementArr).forEach((itm) => {
  Vue.use(itm);
});
